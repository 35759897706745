<template>
    <div>
        <v-row class="mt-3">
            <v-col>
                <v-btn href="/clubs/general-info" class="text-center" color="color3 color3Text--text" block style="height:120px">
                    <div><i class="fas fa-info-circle fa-3x"></i>
                    <h3>General Info</h3></div>
                </v-btn>
            </v-col>
            <v-col>
                <v-btn href="/clubs/teams"  class="text-center" color="color3 color3Text--text" block style="height:120px">
                    <div><i class="fas fa-volleyball-ball fa-3x"></i>
                    <h3>Teams</h3></div>
                </v-btn>
            </v-col>
            <v-col>
                <v-btn href="/clubs/events"  class="text-center" color="color3 color3Text--text" block style="height:120px">
                    <div><i class="fas fa-calendar-alt fa-3x"></i>
                    <h3>Events</h3></div>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="">
            <v-col>
                <v-btn href="/clubs/membership"  class="text-center" color="color3 color3Text--text" block style="height:120px">
                    <div><i class="fas fa-id-badge fa-3x"></i>
                    <h3>Membership</h3></div>
                </v-btn>
            </v-col>
            <v-col>
                <v-btn href="/clubs/data"  class="text-center" color="color3 color3Text--text" block style="height:120px">
                    <div><i class="fas fa-database fa-3x"></i>
                    <h3>Data Manager</h3></div>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  props: [],
  data () {
    return {}
  },
  computed: {
    clubName () {
      return 'VAVA'
    }
  },
  methods: {},
  watch: {},
  mounted () {},
  components: {}
}
</script>
